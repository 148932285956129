import { hasNames } from "../utils";
import Button from "./button";
import { Link } from "gatsby";
import * as React from "react";

import * as com from "../styles/common.module.scss";
import * as styles from "../styles/news-teaser-card.module.scss";

const NewsTeaserCard = ({
	tag,
	title,
	date,
	copy,
	link,
	className,
	tagFilterFunction,
}) => {
	return (
		<div className={hasNames(styles.card, className)}>
			{tag ? (
				<div
					className={styles.tag}
					onClick={() => {
						if (tagFilterFunction instanceof Function) {
							tagFilterFunction(tag);
						}
					}}>
					{tag}
				</div>
			) : null}
			<Link className={com.inherit} to={link}>
				<h3>{title}</h3>
			</Link>
			{date ? <div className={styles.date}>{date}</div> : null}
			<div className={styles.copy}>{copy}</div>
			<Button type="tertiarySolid" to={link}>
				Read More
			</Button>
		</div>
	);
};

export default NewsTeaserCard;
