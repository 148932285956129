// extracted by mini-css-extract-plugin
export var body = "news-teaser-card-module--body--0163c";
export var bodyLarge = "news-teaser-card-module--bodyLarge--a3c8a";
export var bodyLargeLight = "news-teaser-card-module--bodyLargeLight--4686e";
export var bodySmall = "news-teaser-card-module--bodySmall--e5359";
export var bold = "news-teaser-card-module--bold--65586";
export var card = "news-teaser-card-module--card--1d99c";
export var copy = "news-teaser-card-module--copy--1d773";
export var date = "news-teaser-card-module--date--62ac4";
export var eyebrow = "news-teaser-card-module--eyebrow--e4b01";
export var filter = "news-teaser-card-module--filter--43834";
export var homepageHeadline = "news-teaser-card-module--homepageHeadline--f4149";
export var internalHeadline = "news-teaser-card-module--internalHeadline--ab774";
export var italic = "news-teaser-card-module--italic--b4561";
export var link = "news-teaser-card-module--link--ce05a";
export var linkSmall = "news-teaser-card-module--linkSmall--d10d0";
export var quote = "news-teaser-card-module--quote--a8e2d";
export var tag = "news-teaser-card-module--tag--27a78";