import { hasNames } from "../utils";
import * as React from "react";

import * as styles from "../styles/icon-button.module.scss";

const IconButton = ({ icon, onClick, className }) => {
	return (
		<button onClick={onClick} className={hasNames(styles.button, className)}>
			{icon}
		</button>
	);
};

export default IconButton;
