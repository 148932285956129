// extracted by mini-css-extract-plugin
export var body = "news-template-module--body--15266";
export var bodyLarge = "news-template-module--bodyLarge--1da0d";
export var bodyLargeLight = "news-template-module--bodyLargeLight--dce7b";
export var bodySmall = "news-template-module--bodySmall--f09f4";
export var bold = "news-template-module--bold--cec96";
export var browedHeader = "news-template-module--browedHeader--3b7a5";
export var centerlineButter = "news-template-module--centerlineButter--8c1bc";
export var centerlineHeader = "news-template-module--centerlineHeader--9e830";
export var chevron = "news-template-module--chevron--5559e";
export var col0 = "news-template-module--col0--8f939";
export var col1 = "news-template-module--col1--e5108";
export var col10 = "news-template-module--col10--860f1";
export var col11 = "news-template-module--col11--a6ca5";
export var col12 = "news-template-module--col12--16fc5";
export var col2 = "news-template-module--col2--d5ed9";
export var col3 = "news-template-module--col3--383ba";
export var col4 = "news-template-module--col4--94932";
export var col5 = "news-template-module--col5--7fbd2";
export var col6 = "news-template-module--col6--38fca";
export var col7 = "news-template-module--col7--7bd6b";
export var col8 = "news-template-module--col8--6a242";
export var col9 = "news-template-module--col9--9007a";
export var colLg0 = "news-template-module--colLg0--0e5b9";
export var colLg1 = "news-template-module--colLg1--3a03e";
export var colLg10 = "news-template-module--colLg10--bdfcc";
export var colLg11 = "news-template-module--colLg11--60bf8";
export var colLg12 = "news-template-module--colLg12--82e61";
export var colLg2 = "news-template-module--colLg2--b17cd";
export var colLg3 = "news-template-module--colLg3--c925b";
export var colLg4 = "news-template-module--colLg4--12c09";
export var colLg5 = "news-template-module--colLg5--a1c55";
export var colLg6 = "news-template-module--colLg6--16ef4";
export var colLg7 = "news-template-module--colLg7--984ad";
export var colLg8 = "news-template-module--colLg8--0c95d";
export var colLg9 = "news-template-module--colLg9--c1956";
export var colMd0 = "news-template-module--colMd0--32ee2";
export var colMd1 = "news-template-module--colMd1--4b7ed";
export var colMd10 = "news-template-module--colMd10--b4a01";
export var colMd11 = "news-template-module--colMd11--d6f84";
export var colMd12 = "news-template-module--colMd12--b7944";
export var colMd2 = "news-template-module--colMd2--de580";
export var colMd3 = "news-template-module--colMd3--a8740";
export var colMd4 = "news-template-module--colMd4--0c56f";
export var colMd5 = "news-template-module--colMd5--137a7";
export var colMd6 = "news-template-module--colMd6--c7dd2";
export var colMd7 = "news-template-module--colMd7--3dac0";
export var colMd8 = "news-template-module--colMd8--e32d3";
export var colMd9 = "news-template-module--colMd9--ef1c9";
export var colSm0 = "news-template-module--colSm0--16580";
export var colSm1 = "news-template-module--colSm1--6de58";
export var colSm10 = "news-template-module--colSm10--7ae8d";
export var colSm11 = "news-template-module--colSm11--53bcb";
export var colSm12 = "news-template-module--colSm12--bf722";
export var colSm2 = "news-template-module--colSm2--cb3b4";
export var colSm3 = "news-template-module--colSm3--c21c3";
export var colSm4 = "news-template-module--colSm4--7fcf6";
export var colSm5 = "news-template-module--colSm5--cd8f3";
export var colSm6 = "news-template-module--colSm6--9da8d";
export var colSm7 = "news-template-module--colSm7--f3441";
export var colSm8 = "news-template-module--colSm8--3bd5d";
export var colSm9 = "news-template-module--colSm9--e9648";
export var container = "news-template-module--container--84a8b";
export var eyebrow = "news-template-module--eyebrow--26524";
export var filter = "news-template-module--filter--01893";
export var filterBox = "news-template-module--filterBox--8c2fd";
export var gridContainer = "news-template-module--gridContainer--85670";
export var homepageHeadline = "news-template-module--homepageHeadline--ec5f4";
export var iconWrapper = "news-template-module--iconWrapper--9cf70";
export var imageClipped = "news-template-module--imageClipped--07dc2";
export var imageClippedEx = "news-template-module--imageClippedEx--0679c";
export var imageClippedLeft = "news-template-module--imageClippedLeft--5e2c1";
export var imageUnClipped = "news-template-module--imageUnClipped--03f6a";
export var inherit = "news-template-module--inherit--25bb2";
export var internalHeadline = "news-template-module--internalHeadline--4a83a";
export var italic = "news-template-module--italic--321e8";
export var leftAlignedButton = "news-template-module--leftAlignedButton--446a6";
export var link = "news-template-module--link--8d2ef";
export var linkSmall = "news-template-module--linkSmall--d53d1";
export var loadMoreSection = "news-template-module--loadMoreSection--a2d28";
export var magnifyingGlass = "news-template-module--magnifyingGlass--91472";
export var monobrow = "news-template-module--monobrow--cc1da";
export var noResultsSection = "news-template-module--noResultsSection--3ae53";
export var option = "news-template-module--option--bd2a0";
export var optionsWrapper = "news-template-module--optionsWrapper--9035a";
export var quote = "news-template-module--quote--f0722";
export var searchInputWrapper = "news-template-module--searchInputWrapper--c3430";
export var searchWrapper = "news-template-module--searchWrapper--3dbb9";
export var sectionSpacer = "news-template-module--sectionSpacer--dacec";
export var selectWrapper = "news-template-module--selectWrapper--a536e";
export var selectedTag = "news-template-module--selectedTag--b440b";
export var textInput = "news-template-module--textInput--51a38";